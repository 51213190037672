import React from "react"
import LayoutEng from "../../components/LayoutEng"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../../components/SEO"

const About = () => {
  return (
    <LayoutEng>
      <SEO title="About" />
      <main className="about-page page">
      <section>
        <div>
          <div className="about-columns">
            <div className="logoScreen">
            <ul className="logoImg">
            <li><StaticImage
            src="../../assets/images/logo.png"
            alt="Apsiha"
            className="logoInner"
            placeholder="DOMINANT_COLOR"
            //layout="constrained"
            ></StaticImage>
            <h2 className="center hidden-lg"><strong className="pageNaslov">Apsiha </strong><span className="hidden-lg" id="naslov_span"> Psychotherapy</span></h2>
            </li>
            </ul>
            </div>
            <div>
            <p>We are so glad you are here!</p> 
            <p>You are probably going through a challenging phase or considering a life change. 
            You are also wondering if we are what you need just now. We certainly want to help you in your decision. 
            </p>
            <p>
            We will try to answer your questions, listen to your needs, be present, provide support, nurture hope and look forward to your progress. 
            Our practice offers counselling and psychotherapy which are:
            </p>
            <ul className="popisX">
              <li>Trauma-informed</li>
              <li>Flexible</li>
              <li>Culturally sensitive</li>
            </ul>
            <p>
            We founded Apsiha in 2016 because we wanted to create a practice that we would personally like to walk into, 
            encouraged by extensive and international experience in clinical, counselling and organizational psychology.
            </p>
            <p>
            We continuously educate ourselves and supervise our work to ensure that you are in good and competent hands on your therapeutic journey. 
            You can find out more about what we offer in the section of the website dedicated to our <Link to="../Services">services</Link>.
            </p>
            <p>
            You can visit us in person or opt for a video call. Regardless of the communication channel you prefer, you can find the <Link to="../Contact">details here</Link>.
            </p>
            <br/> 
            </div>
          </div>
          <div className="profile">
              <h2><strong className="pageNaslov">Apsiha </strong>in the media</h2>
              <p>
              Here you can find video clips as well as links to articles and blogs of our psychotherapists
              </p>
              <div>
              <Link to="../Media" className="btn">
                Show more &raquo;
              </Link>
              </div>
              </div>
          <div className="container">
            <div className="profile boxed1" id="ivana">
            <div>
              <StaticImage
              src="../../assets/images/ivana.jpeg"
              alt="Ivana"
              className="ivana-profile-img"
              placeholder="DOMINANT_COLOR"
              />
              </div>
              <div className="profileTextIvana">
              <h2>Ivana Mrgan <span className="kurziv"> univ. spec. clin. psych.</span></h2>
              <p>I am passionate about clinical psychology, psychotherapy and people in general. 
                Openness to different experiences, empathy and authentic relationships are something that defined me in my career. 
                These are also the values that I will bring with me into our relationship if you decide to work with me. 
                I am particularly keen on openness, compassion, multiculturalism and a culturally sensitive approach to clients. 
                </p>
              <div className="IvanaBtn">
              <Link to="../Ivana"  className="btn">
                Show more &raquo;
              </Link>
              </div>
              </div>
            </div>
            <div className="profile boxed2" id="ana">
            <div>
              <StaticImage
              src="../../assets/images/ana.jpeg"
              alt="Ana"
              className="ana-profile-img"
              placeholder="DOMINANT_COLOR"
              />
              </div>
              <div className="profileTextAna">
              <h2>Ana Miletić<span className="kurziv"> prof. psych.</span></h2>
              <p>I was always fascinated with people. In psychotherapy I have found the best use of my curiosity and my presence. 
                It's a privilege to be near most vulnerable human experiences so I aim to be both gentile and bold in approaching 
                them and going through them with my clients. On that journey with me, you can find understanding, 
                support and trust in yourself and your capabilities.
              </p>
              <div>
              <Link to="../Ana" className="btn">
                Show more &raquo;
              </Link>
              </div>
              </div>
            </div>
          </div>
            <div className="profile">
              <h2>Origami</h2>
              <p>
              The Artist behind the origami on the Apsiha website is Jo Nakashima. If you are interested in how they are made, check out his site
              </p>
              <a href="https://jonakashima.com.br/" className="btn">
                Jo Nakashima
              </a>
              </div>
          </div>
        </section>
      </main>
    </LayoutEng>
  )
}

export default About
